<template>
  <div class="home">
    <img class="illustration" src="@/assets/home-illustration.svg">
    <h1>{{ $t('home.title') }}</h1>
    <div class="closing-date">
      {{ $t('closureDate') }} de <span class="date input-period">{{ $t("frenchMonths")[currentClosureDate.month - 1 ] + ' ' +currentClosureDate.year.toString() }} </span> :
      {{ $t('until') }}
      <span class="date"> {{ formatDate(currentClosureDate.DataAnalytiqueclosureEndDate) }}</span>
    </div>
    <div class="summary">
      <div class="card">
        <div class="title">{{ $t('home.declarations') }}</div>
        <div class="value">{{ entries.length }}</div>
      </div>
      <div class="card">
        <div class="title">{{ $t('home.mouvementNumber') }}</div>
        <div class="value">{{ sum('amount') }} €</div>
      </div>
    </div>
    <div class="history">
      <h2>{{ $t('home.declarationsHistory') }}</h2>
      <loading v-if="loading" />
      <GridEntries v-else :entries="lastEntries" :headers="headers" />
    </div>
  </div>
</template>

<script>
  import GridEntries from '@/components/GridEntries'
  import _ from 'lodash'
  import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
  import { formatDate } from '@/utils/utils'
  import { defaultStartDate, defaultEndDate } from '@/utils/CONF'

  export default {
    name: 'Home',
    components: {
      GridEntries
    },
    data() {
      return {
        loading: true,
        headers: ['user', 'date', 'imputationDate', 'format', 'accountingUnit', 'siteId', 'resultCenterId', 'family', 'amount', 'label', 'entryType']
      }
    },
    computed: {
      ...mapGetters('entries', ['entries']),
      ...mapGetters('configuration', ['currentClosureDate']),
      ...mapState('configuration', ['habilitations', 'startDate', 'endDate']),
      isUserCDG() {
        return this.$roles.dataanalytique && this.$roles.dataanalytique.includes('cdg')
      },
      lastEntries() {
        return this.entries ? this.entries.sort((a, b) => b.date.seconds - a.date.seconds).slice(0, 30) : []
      }
    },
    async created() {
      this.loading = true
      this.setPeriod({ startDate: defaultStartDate, endDate: defaultEndDate })
      await this.fetchEntries({
        user: this.$userInfos.uid,
        site: this.habilitations.site,
        format: this.habilitations.formatType
      })
      .finally(() => {
        this.loading = false
      })
    },
    methods: {
      ...mapMutations({ setPeriod: 'configuration/SET_PERIOD' }),
      ...mapActions('entries', ['fetchEntries']),
      formatDate,
      sum(key) {
        const sum = this.entries.reduce((acc, entry) => acc + (entry[key] || 0), 0)
        return this.chunk(sum.toFixed(2))
      },
      chunk(x, n) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home {
    padding-top: 6.4rem;
    position: relative;
    overflow-y: scroll;
    @media print {
      overflow: visible;
    }
    .illustration {
      max-height: 32rem;
      max-width: 25vw;
      position: absolute;
      right: 2rem;
      top: 2rem;
    }

    .closing-date {
      margin: 2rem 0;
      .date {
        @extend %font-bold;
      }
    }
    .summary {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 3.6rem;
      width: calc(75vw - #{$sidebar-width});
      margin-bottom: 6.4rem;
      .card {
        border: 1px solid $dark-grey;
        background-color: $white;
        padding: 2.4rem;
        border-radius: 16px;
        min-height: 10.5rem;
        .title {
          font-size: 2rem;
          @extend %font-bold;
          margin-bottom: 1.6rem;
        }
        .value {
          @extend %font-black;
          font-size: 4.8rem;
        }
      }
    }
    .history {
      h2 {
        @extend %font-bold;
        font-size: 2.5rem;
        margin-bottom: 3.2rem;
      }
    }
  }
</style>
